.login-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-container {
    overflow: auto;
    width: 100%;
    height: 100%;
}

.admin-content {
    background-color: #ffffff;
    margin: 0 auto;
    width: 100vw;
    max-width: 800px;
    padding: 1rem;
}

.admin-container .menu-button-container > * {
    margin: 1rem 1rem 1rem 0;
}

.MuiListItem-container {
    background-color: #e0e0e0;
}

.MuiListItemText-root .MuiTypography-body1 {
    font-weight: bold;
}

.file-upload {
    margin-top: 2rem;
}

.MuiButtonBase-root.table-add-button {
    margin-top: 0.5rem;
}